<script>
  import { onMount } from 'svelte';
  import Btn from './ui/Btn.svelte';
  import BtnShadow from './ui/BtnShadow.svelte';
  export let media;

  let mediaContainer;
  let iframeBlock;

  onMount(_ => {
    (media || {}).type === 'iframe' && setContainerHeight();
  });

  function setContainerHeight() {
    mediaContainer.style.height = `calc(100vh - ${
      mediaContainer.getBoundingClientRect().top
    }px)`;
    mediaContainer.children[0].style.visibility = 'visible';
  }
</script>

{#if media}
  <div bind:this={mediaContainer}>
    {#if media.type === 'image'}
      <img
        on:load={setContainerHeight}
        src={media.src}
        alt=""
        class="h-full w-auto invisible rounded-t-lg"
        style="max-width: none;" />
    {/if}
    {#if media.type === 'iframe'}
      <div
        class="relative h-full rounded-t-lg bg-white overflow-hidden invisible"
        style="width: 350px;">
        <iframe
          title="setContainerHeight"
          src={media.src}
          class="w-full h-full"
          frameborder="0" />
        <div
          class="absolute flex items-center justify-center inset-0"
          bind:this={iframeBlock}>
          <div class="absolute inset-0 bg-black opacity-25" />

          <div class="z-20">
            <BtnShadow on:click={_ => (iframeBlock.style.display = 'none')}>
              Essayer
            </BtnShadow>
          </div>

        </div>
      </div>
    {/if}
  </div>
{/if}
