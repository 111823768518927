<script>
  import { onMount } from 'svelte';

  let container;
  let sectionsContainer;

  onMount(_ => {
    const searchParams = new URLSearchParams(window.location.search);
    const section = searchParams.get('section');
    if (!section) return;
    const target = sectionsContainer.children[section];
    if (!target) return;
    setTimeout(() => {
      container.scroll({
        left: target.offsetLeft - 25,
        behavior: 'smooth'
      });
    }, 200);
    // container.scrollLeft(target.offsetLeft);
  });

  export let sections;
</script>

<div
  class="px-2 overflow-x-scroll"
  bind:this={container}
  on:wheel|preventDefault={({ deltaY, deltaX, toElement }) => {
    container.scrollLeft += deltaY + deltaX;
  }}>
  <div class="flex px-6 -mx-2" bind:this={sectionsContainer}>
    {#each sections as section}
      <slot {section} />
    {/each}
  </div>
</div>
