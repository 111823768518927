import { readable } from 'svelte/store';

export const location = readable(window.location.hash, function start(set) {
  console.log(window.location.hash);
  window.onhashchange = () => {
    console.log('roto');
    console.log('prout');
    set(window.hash);
  };

  // return function stop() {
  //   clearInterval(interval);
  // };
});
