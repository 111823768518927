import basic from './assets/emails/basic.png';
import guide from './assets/guide.png';

import dashboard from './assets/dashboard.jpg';
import results from './assets/results.jpg';
import actions from './assets/actions.jpg';
import services from './assets/services.jpg';
import comparator from './assets/comparator.jpg';
import explorer from './assets/explorer.jpg';
import filters from './assets/filters.jpg';
import pptReports from './assets/ppt-report.jpg';
import liveBrainstorming from './assets/live-brainstorming.jpg';
import liveQuizz from './assets/live-quizz.jpg';
import liveStandup from './assets/live-standup.jpg';
import liveWordcloud from './assets/live-wordcloud.jpg';

import notifications from './assets/notifications.jpg';
import pim from './assets/pim.jpg';

import newCampaign from './assets/new-campaign.jpg';
import campaignRelance from './assets/campaign-relance.jpg';
import campaignManager from './assets/campaign-manager.jpg';
import customEmail from './assets/custom-email.jpg';

export default {
  routes: [
    {
      name: 'mesure',
      display_name: 'Mesurer',
      sections: [
        {
          title: '🗞 Micro campagne',
          content: '30s pour répondre, +80% de taux de reponse.',
          size: 'md',
          media: {
            type: 'iframe',
            src: 'https://employee.jubiwee.com/demo'
          }
        },
        {
          title: '📮 Module Restitution',
          content: 'Résultats partagés aux participants.',
          size: 'md',
          media: {
            type: 'iframe',
            src: 'https://employee.jubiwee.com/demo/restitution'
          }
        },
        {
          title: '❓ Module Whois',
          content: 'Module de reconnaissance réinventé.',
          size: 'md',
          media: {
            type: 'iframe',
            src: 'https://employee.jubiwee.com/demo/whois'
          }
        },
        {
          title: '🔴 Notifications',
          content: 'Emails, SMS, Microsoft Teams ou Slack ou bornes.',
          media: {
            type: 'image',
            src: notifications
          }
        },
        {
          title: '🎨 Personnalisation',
          content: 'Campagnes ciblées et personnalisables.',
          media: {
            type: 'image',
            src: customEmail
          }
        }
      ]
    },

    {
      name: 'analyse',
      display_name: 'Analyser',
      sections: [
        {
          title: '🌍 Centre de pilotage',
          content: 'Un tableau de bord intuitif adapté à chaque contexte.',
          size: 'lg',
          media: {
            type: 'image',
            src: dashboard
          },
          link: {
            text: 'Accéder au dashboard',
            url: 'https://guide.jubiwee.com'
          }
        },
        {
          title: '📡 Résultats en temps réel',
          content: 'Comprendre vos résultats prend 30 secondes.',
          size: 'lg',
          media: {
            type: 'image',
            src: results
          },
          link: {
            text: 'Accéder au dashboard',
            url: 'https://guide.jubiwee.com'
          }
        },
        {
          title: '👥 Filtres par population',
          content: 'Des filtres personnalisés pour une lecture pertinente.',
          media: {
            type: 'image',
            src: filters
          }
        },
        {
          title: '🔀 Comparateur de résultats',
          content:
            'Comparez simplement les résultats entre plusieurs périmètres.',
          media: {
            type: 'image',
            src: comparator
          }
        },
        {
          title: '🗺 Explorer Global',
          content: 'Découvrez en un clic les plus fortes variations.',
          media: {
            type: 'image',
            src: explorer
          }
        },
        // {
        //   title: '🕵 Product Insight Manager',
        //   content: 'Exporter un rapport PDF à tout moment.'
        // },
        {
          title: '📄 Rapport Powerpoint',
          media: {
            type: 'image',
            src: pptReports
          },
          content: 'Exportez et partagez un rapport PDF/PPT à tout moment.'
        },
        {
          title: '📈 Notifications push',
          content: 'Alertes automatiques envoyées par email.',
          media: {
            type: 'image',
            src: campaignManager
          }
        }
      ]
    },

    {
      name: 'improve',
      display_name: 'Améliorer',
      sections: [
        {
          title: '🕵 People Insights Manager',
          content: 'Un(e) analyste surveille vos résultats pour vous !',
          size: 'md',
          media: {
            type: 'image',
            src: pim
          }
        },
        {
          title: '🏃 Module actions',
          content: 'Partagez et inspirez-vous des meilleures pratiques.',
          media: {
            type: 'image',
            src: actions
          }
        },
        {
          title: '🚀 Services',
          content:
            'Découvrez et réservez des services sur-mesure pour améliorer vos résultats.',
          media: {
            type: 'image',
            src: services
          }
        }
        // {
        //   title: '📬 Nouvelle campagne',
        //   content: "L'email est complétement personnalisable",
        //   media: {
        //     type: 'image',
        //     src: newCampaign
        //   }
        // },
        // {
        //   title: '🔄 Relance automatique',
        //   content: 'Après 3 jours la relance  part pour les non-répondants.',
        //   media: {
        //     type: 'image',
        //     src: campaignRelance
        //   }
        // },
        // {
        //   title: '📈 Résultats managers',
        //   content: 'lorem12',
        //   media: {
        //     type: 'image',
        //     src: campaignManager
        //   }
        // }
      ]
    },
    {
      name: 'live',
      display_name: 'LIVE',
      sections: [
        {
          title: '💡 Brainstorming',
          content: `Générer un maximum d'idée autour d'une problématique, puis chacun vote pour ses préférées.`,
          size: 'md',
          media: {
            type: 'image',
            src: liveBrainstorming
          }
        },
        {
          title: '🔍 Quizz',
          content: `Interroger vos collaborateurs rapidement en créant vos propres questions. Qui trouvera la bonne réponse ?`,
          size: 'md',
          media: {
            type: 'image',
            src: liveQuizz
          }
        },
        {
          title: '✊ Standup',
          content: `Donner la parole à chacun en répartissant le temps de parole équitablement.`,
          size: 'md',
          media: {
            type: 'image',
            src: liveStandup
          }
        },
        {
          title: '🖋 En 1 mot',
          content: `Exprimer un concept en 1 mot: les réponses apparaisent petit à petit.`,
          size: 'md',
          media: {
            type: 'image',
            src: liveWordcloud
          }
        }
      ]
    }
  ]
};
