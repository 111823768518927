<script>
  import { onMount } from 'svelte';
  import { navigate, Router, Link, Route } from 'svelte-routing';
  import model from './model.js';

  import FeaturesList from './components/FeaturesList.svelte';
  import SectionContainer from './components/SectionContainer.svelte';
  import PlayContainer from './components/PlayContainer.svelte';
  import SectionMedia from './components/SectionMedia.svelte';
  import SectionColumn from './components/SectionColumn.svelte';
  import Navigation from './components/Navigation.svelte';
  import NavigationLink from './components/NavigationLink.svelte';

  import { location } from './location.store.js';

  // onMount(_ => {
  //   const path = window.location.pathname.substr(1);
  //   if (!model.routes.map(r => r.name).includes(path)) {
  //     navigate('/surveys');
  //   }
  // });
</script>

<style>
  :global(html) {
    @apply font-sans relative subpixel-antialiased text-gray-800 bg-gray-100;
    @apply h-full;
    /* background: #f7faff; */
  }

  :global(html, body) {
    @apply overflow-hidden;
    /* @apply flex flex-col; */
  }

  :global(ul.nav li.active) {
    @apply bg-gray-800 text-gray-200;
  }
</style>

<!-- <iframe
  src="https://jubiwee.daily.co/hello"
  class="z-10"
  style="border: none; position: fixed; top: 0; right: 0; margin-top: -31px;
  width: 320px; height: calc(100vh + 31px) ;" /> -->

<Router>
  <Navigation items={model.routes} let:item let:active>
    <NavigationLink {active}>{item.display_name}</NavigationLink>
  </Navigation>

  <Route path="/">
    <div class="px-6 lg:px-12 py-8">
      <FeaturesList />
    </div>
  </Route>

  <Route path="play">
    <PlayContainer />
  </Route>

  {#each model.routes as route}
    <Route path={route.name}>
      <SectionContainer sections={route.sections} let:section>
        <SectionColumn {section} />
      </SectionContainer>
    </Route>
  {/each}
</Router>
