<script>
  import { getContext } from 'svelte';
  import { Link } from 'svelte-routing';
  import { ROUTER } from 'svelte-routing/src/contexts';
  var { activeRoute } = getContext(ROUTER);
  import NavigationLink from './NavigationLink.svelte';
  export let items;
</script>

<div class="hidden md:block">
  <div class="px-6 pt-4 pb-4 bg-white border-b border-gray-200">
    <div
      class="flex items-center text-gray-700 select-none leading-none
      whitespace-no-wrap overflow-hidden">
      <Link to="/">
        <NavigationLink
          active={$activeRoute && $activeRoute.route.path === '/'}>
          <span class="font-semibold">• Jubiwee demo</span>
        </NavigationLink>
      </Link>

      {#each items as item}
        <Link to={item.name}>
          <slot
            {item}
            active={$activeRoute && item.name === $activeRoute.route.path.slice(0, -1)} />
        </Link>
      {/each}
      <!-- <div class="ml-auto">
        <Link to="play">
          <div class="flex items-center">
            <svg viewBox="0 0 24 24" width="24" height="24" class="mr-2">
              <g fill="#000000">
                <path
                  fill="#000000"
                  d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z
                  M9,17V7l8,5L9,17z" />
              </g>
            </svg>
            Play
          </div>
        </Link>
      </div> -->
    </div>
  </div>
</div>

<div class="md:hidden">
  <div
    class="flex items-center justify-center fixed bottom-0 mb-6 mr-6 right-0
    z-30 h-12 w-12 rounded-full bg-blue-200 shadow-lg border border-blue-200">
    <Link to="/">
      <svg viewBox="0 0 24 24" width="18" height="18">
        <path
          d="M23.633,10.226l-11-9a1,1,0,0,0-1.266,0l-11,9a1,1,0,0,0,1.266,1.548L3,10.656V22a1,1,0,0,0,1,1h6V18h4v5h6a1,1,0,0,0,1-1V10.656l1.367,1.118a1,1,0,1,0,1.266-1.548ZM14,14H10V10h4Z"
          fill="rgb(55, 119, 255)" />
      </svg>
    </Link>
    <!-- <div
      class="bg-blue-200 hover:bg-blue-300 text-blue-500 px-4 rounded-lg py-1
      md:py-2 shadow-xl hover:shadow border-2 border-blue-100 select-none
      cursor-pointer tracking-wide font-semibold">
    </div> -->
  </div>
</div>
