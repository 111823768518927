<script>
  import { Link } from 'svelte-routing';
  import model from '../model.js';
</script>

<ul class="md:flex">
  {#each model.routes as { name, display_name, sections }}
    <div class="md:w-1/3">
      <li class="font-semibold mb-4">{display_name}</li>
      <ul class="mb-5">
        {#each sections as section, index}
          <Link to={name + '?section=' + index}>
            <li class="mb-1 text-blue-500 hover:text-blue-700">
              {section.title}
            </li>
          </Link>
        {/each}
      </ul>
    </div>
  {/each}
</ul>
