<script>
  import SectionMedia from './SectionMedia.svelte';
  export let section;

  // const sizes = {
  //   sm: 'md:w-96 lg:w-1/3',
  //   md: 'md:w-96',
  //   lg: 'w-full'
  // };
</script>

<div class="px-2">
  <div class="py-6">
    <h2 class="text-xl font-semibold leading-tight text-gray-800 mb-1">
      {section.title}
    </h2>
    <p class="text-gray-600">{section.content}</p>
  </div>

  <SectionMedia media={section.media} />
</div>
